
<template>

    <div id="dashboard-analytics">
        <div class="vx-row">
            <div class="vx-col w-full lg:w-1/2 mb-base">
                <vx-card title="Card Request">
                    <form-wizard color="rgba(var(--vs-primary), 1)" errorColor="rgba(var(--vs-danger), 1)" :title="null" :subtitle="null" finishButtonText="Submit">
                        <tab-content title="What kind of Cactus Card do you want?" class="mb-5" icon="feather icon-home" :before-change="validateStep1">
                            <!-- tab 1 content -->
                            <form data-vv-scope="step-1">
                                <div class="vx-row">
                                    <div class="vx-col w-full md:w-1/3 sm:w-1/2 mb-base" v-for="item in cardTypes" :key="item.id">
                                        <vx-card @click="cardType = item.id" class="text-center cursor-pointer" :class="cardType == item.id? 'active-card-type' :''" >
                                            <img :src="item.graphic" alt="graphic" width="180" class="mx-auto mb-4">
                                            <span class="mb-2">{{ item.title.toUpperCase() }}</span><br>
                                            <span class="text-primary">{{ item.description }}</span>
                                        </vx-card>
                                    </div>
                                </div>
                            </form>
                        </tab-content>

                        <!-- tab 2 content -->
                        <tab-content title="Personal Details" subtitle="Enter your personal details. We will verify it!" class="mb-5" icon="feather icon-user" :before-change="validateStep2">
                            <form data-vv-scope="step-2">

                                    <vs-alert>
                                        Enter your personal details. We will verify it!
                                    </vs-alert>
                                <div class="vx-row">
                                    <div class="vx-col md:w-1/2 w-full">
                                        <vs-input label="Name" v-model="person.name" class="w-full mt-4" name="name" v-validate="'required|alpha_spaces'" />
                                        <span class="text-danger">{{ errors.first('step-2.name') }}</span>

                                        <vs-input label="Mother Last Name" v-model="person.lastNameMother" class="w-full mt-4" name="lastNameMother" v-validate="'required|alpha_spaces'" />
                                        <span class="text-danger">{{ errors.first('step-2.lastNameMother') }}</span>
                                    </div>
                                    <div class="vx-col md:w-1/2 w-full">
                                        <vs-input label="Father Last Name" v-model="person.lastNameFather" class="w-full mt-4" name="lastNameFather" v-validate="'required|alpha_spaces'" />
                                        <span class="text-danger">{{ errors.first('step-2.lastNameFather') }}</span>

                                        <vs-input label="Passport Number"  v-model="person.passport" class="w-full mt-4" name="passport" v-validate="'required'" />
                                        <span class="text-danger">{{ errors.first('step-2.passport') }}</span>
                                    </div>
                                </div>
                                <div class="vx-row">
                                    <div class="vx-col md:w-1/3 w-full">
                                        <vs-input label="Email" v-model="person.email" class="w-full mt-4" name="email" v-validate="'required|email'" />
                                        <span class="text-danger">{{ errors.first('step-2.email') }}</span>
                                    </div>
                                    <div class="vx-col md:w-1/3 w-full">
                                        <flat-pickr placeholder="Date of birth"  :config="configdateTimePicker" class="w-full mt-10"
                                                    style="height: 41px;" name="birthday"
                                                    v-validate="'required'"
                                                    minDate= "today"
                                                    v-model="person.birthDate"/>
                                        <span class="text-danger">{{ errors.first('step-2.birthday') }}</span>
                                    </div>
                                    <div class="vx-col md:w-1/3 w-full">
                                        <vs-select v-model="person.gender" class="w-full select-large mt-4" label="Gender">
                                            <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in genreOptions" class="w-full" />
                                        </vs-select>
                                    </div>

                                </div>




                            </form>
                        </tab-content>

            <!-- tab 3 content -->
            <tab-content title="Address" class="mb-5" icon="feather icon-credit-card" :before-change="validateStep3">
                <form data-vv-scope="step-3">
                    <vx-card title="Residential Address" >
                    <vs-alert>
                        Enter your residential address as it appears on their recent utility bill or bank statement
                    </vs-alert>
                    <div class="vx-row">
                        <div class="vx-col md:w-1/1 w-full">
                            <vs-input label="Address" v-model="address.address" class="w-full mt-5" name="address" v-validate="'required'" />
                            <span class="text-danger">{{ errors.first('step-3.address') }}</span>
                        </div>
                        <div class="vx-col md:w-1/3 w-full">
                            <vs-select name="country" v-model="address.country" class="w-full select-large mt-5" label="Country">
                                <vs-select-item :key="index" :value="item.id" :text="item.name" v-for="(item,index) in user.countries" class="w-full" />
                            </vs-select>
                            <span class="text-danger">{{ errors.first('step-3.country') }}</span>
                        </div>
                        <div class="vx-col md:w-1/3 w-full">
                            <vs-select name="state" v-model="address.state" class="w-full select-large mt-5" label="State">
                                <vs-select-item :key="index" :value="item.id" :text="item.name" v-for="(item,index) in user.states" class="w-full" />
                            </vs-select>
                            <span class="text-danger">{{ errors.first('step-3.state') }}</span>
                        </div>
                        <div class="vx-col md:w-1/3 w-full">
                            <vs-input label="City" v-model="address.city" class="w-full mt-4" name="city" v-validate="'required'" />
                            <span class="text-danger">{{ errors.first('step-3.city') }}</span>
                        </div>

                        <div class="vx-col md:w-1/3 w-full">
                            <vs-input label="Post/ZIP Code" v-model="address.zip" class="w-full mt-4" name="zip" v-validate="'required'" />
                            <span class="text-danger">{{ errors.first('step-3.zip') }}</span>
                        </div>
                        <div class="vx-col md:w-1/3 w-full">
                            <vs-input label="Phone Code" v-model="address.phoneCode" class="w-full mt-4" name="phoneCode" v-validate="'required'" />
                            <span class="text-danger">{{ errors.first('step-3.phoneCode') }}</span>
                        </div>
                        <div class="vx-col md:w-1/3 w-full">
                            <vs-input label="Phone Number" v-model="address.phoneNumber" class="w-full mt-4" name="phoneNumber" v-validate="'required'" />
                            <span class="text-danger">{{ errors.first('step-3.phoneNumber') }}</span>
                        </div>
                        <div class="vx-col md:w-1/2 w-full">
                            <vs-select name="bornCountry" v-model="address.bornCountry" class="w-full select-large mt-5" label="Birth Country">
                                <vs-select-item :key="index" :value="item.id" :text="item.name" v-for="(item,index) in user.countries" class="w-full" />
                            </vs-select>
                            <span class="text-danger">{{ errors.first('step-3.bornCountry') }}</span>
                        </div>
                        <div class="vx-col md:w-1/2 w-full">
                            <vs-input label="Birth City" v-model="address.bornCity" class="w-full mt-4" name="bornCity" v-validate="'required'" />
                            <span class="text-danger">{{ errors.first('step-3.bornCity') }}</span>
                        </div>
                    </div>
                    </vx-card>


                <vx-card title="Card Delivery Address" v-if="cardType != 'virtual'" class="mt-base">

                    <vs-alert>
                        The card would be delivered to this address. Please ensure to provide the correct address
                    </vs-alert>
                    <div class="vx-row">
                        <div class="vx-col md:w-1/1 w-full">
                        <vs-checkbox  color="success" v-model="sameAddress">Use same Address</vs-checkbox>
                        </div>
                    </div>
                    <div class="vx-row">
                        <div class="vx-col md:w-1/1 w-full">
                            <vs-input label="Address" v-model="shippingAddress.address" class="w-full mt-5" name="address2" v-validate="'required'" />
                            <span class="text-danger">{{ errors.first('step-3.address2') }}</span>
                        </div>
                        <div class="vx-col md:w-1/3 w-full">
                            <vs-select name="country2" v-model="shippingAddress.country" class="w-full select-large mt-5" label="Country">
                                <vs-select-item :key="index" :value="item.id" :text="item.name" v-for="(item,index) in user.countries" class="w-full" />
                            </vs-select>
                            <span class="text-danger">{{ errors.first('step-3.country2') }}</span>
                        </div>
                        <div class="vx-col md:w-1/3 w-full">
                            <vs-select name="state2" v-model="shippingAddress.state" class="w-full select-large mt-5" label="State">
                                <vs-select-item :key="index" :value="item.id" :text="item.name" v-for="(item,index) in user.states" class="w-full" />
                            </vs-select>
                            <span class="text-danger">{{ errors.first('step-3.state2') }}</span>
                        </div>
                        <div class="vx-col md:w-1/3 w-full">
                            <vs-input label="City" v-model="shippingAddress.city" class="w-full mt-4" name="shippingCity" v-validate="'required'" />
                            <span class="text-danger">{{ errors.first('step-3.shippingCity') }}</span>
                        </div>

                        <div class="vx-col md:w-1/3 w-full">
                            <vs-input label="Post/ZIP Code" v-model="shippingAddress.zip" class="w-full mt-4" name="zip2" v-validate="'required'" />
                            <span class="text-danger">{{ errors.first('step-3.zip2') }}</span>
                        </div>
                        <div class="vx-col md:w-1/3 w-full">
                            <vs-input label="Phone Code" v-model="shippingAddress.phoneCode" class="w-full mt-4" name="phoneCode2" v-validate="'required'" />
                            <span class="text-danger">{{ errors.first('step-3.phoneCode2') }}</span>
                        </div>

                        <div class="vx-col md:w-1/3 w-full">
                            <vs-input label="Phone Number" v-model="shippingAddress.phoneNumber" class="w-full mt-4" name="phoneNumber2" v-validate="'required'" />
                            <span class="text-danger">{{ errors.first('step-3.phoneNumber2') }}</span>
                        </div>
                    </div>

                </vx-card>
                </form>
            </tab-content>


            </form-wizard>
            </vx-card>
        </div>

            <div class="vx-col w-full lg:w-1/2 mb-base" v-if="cardType && cardType == 'virtual'">
                <vx-card title="Benefits of your Cactus Card">
                    <vx-timeline :data="timelineVirtual" />
                </vx-card>
            </div>
            <div class="vx-col w-full lg:w-1/2 mb-base" v-else-if="cardType && cardType != 'virtual'">
                <vx-card title="Benefits of your Cactus Card">
                    <vx-timeline :data="timelinePlastic" />
                </vx-card>
            </div>
    </div>
    </div>

</template>

<script>

    import {mapState, mapActions} from 'vuex'
    import { FormWizard, TabContent } from 'vue-form-wizard'
    import 'vue-form-wizard/dist/vue-form-wizard.min.css'
    import VxTimeline from '@/components/timeline/VxTimeline'
    import 'flatpickr/dist/flatpickr.css';
    import {Spanish as SpanishLocale} from 'flatpickr/dist/l10n/es.js';
    import flatPickr from 'vue-flatpickr-component';
    // For custom error message
    import { Validator } from 'vee-validate';
    const dict = {
        custom: {
            name: {
                required: 'Name is required',
                alpha: "Name may only contain alphabetic characters"
            },
            lastNameFather: {
                required: 'Father Last Name is required',
                alpha: "Father Last Name may only contain alphabetic characters"
            },
            lastNameMother: {
                required: 'Mother Last Name is required',
                alpha: "Mother Last Name may only contain alphabetic characters"
            },
            passport: {
                required: 'Passport is required'
            },
            birthday: {
                required: 'Date of Birth is required'
            },
            address: {
                required: 'Address is required'
            },
            country: {
                required: 'Country is required'
            },
            bornCountry: {
                required: 'Birth Country is required'
            },
            city: {
                required: 'City is required'
            },
            bornCity: {
                required: 'Birth City is required'
            },
            state: {
                required: 'State is required'
            },
            zip: {
                required: 'Zip Code is required'
            },
            phoneCode: {
                required: 'Phone Code is required'
            },
            phoneNumber: {
                required: 'Phone Area is required'
            },
            shippingCity: {
                required: 'City is required'
            },
            address2: {
                required: 'Address is required'
            },
            country2: {
                required: 'Country is required'
            },
            city2: {
                required: 'City is required'
            },
            state2: {
                required: 'State is required'
            },
            zip2: {
                required: 'Zip Code is required'
            },
            phoneCode2: {
                required: 'Phone Code is required'
            },
            phoneNumber2: {
                required: 'Phone Area is required'
            },
            email: {
                required: 'Email is required',
                email: "Please enter valid email"
            }
        }
    };

    // register custom messages
    Validator.localize('en', dict);

    export default {
        data() {
            return {
                cardType: '',
                person:{
                    name: '',
                    lastNameFather: '',
                    lastNameMother: '',
                    passport: '',
                    birthDate: '',
                    email:'',
                    gender: ''
                },
                address:{
                    address: '',
                    country: '',
                    bornCountry: '',
                    bornCity: '',
                    state: '',
                    city: '',
                    zip: '',
                    phoneCode: '',
                    area: '' ,
                    phoneNumber: ''
                },
                shippingAddress:{
                    address: '',
                    country: '',
                    state: '',
                    city: '',
                    zip: '',
                    phoneCode: '',
                    area: '' ,
                    phoneNumber: ''
                },


                sameAddress: false,
                cardTypes: [
                    {
                        id          : 'plastic-usd',
                        title       : 'International Plastic Card',
                        description : 'USD',
                        graphic     : require('@/assets/images/cactus/graphic-4.png'),
                        url         : '/pages/knowledge-base/category'
                    },
                    {
                        id          : 'plastic-eur',
                        title       : 'International Plastic Card',
                        description : 'EUR',
                        graphic     : require('@/assets/images/cactus/graphic-4.png'),
                        url         : '/pages/knowledge-base/category'
                    },
                    {
                        id          : 'virtual',
                        title       : 'International Virtual Card',
                        description : '',
                        graphic     : require('@/assets/images/cactus/graphic-5.png'),
                        url         : '/pages/knowledge-base/category'
                    }
                ],
                timelinePlastic: [
                    {
                        color: 'primary',
                        icon: 'CheckIcon',
                        title: 'Buy comfortably',
                        desc: 'You can use it to do transactions online, offline and at ATMs.',
                        time: ''
                    },
                    {
                        color: 'primary',
                        icon: 'CheckIcon',
                        title: 'Chip card technology',
                        desc: "CactusCard complies with the new credit and debit card's technology standards.",
                        time: ''
                    },
                    {
                        color: 'primary',
                        icon: 'CheckIcon',
                        title: 'Easy to get',
                        desc: 'You can start using your card as soon as you receive it. No cumbersome procedures or complicated registration processes.',
                        time: ''
                    },
                    {
                        color: 'primary',
                        icon: 'CheckIcon',
                        title: 'Completely secure',
                        desc: 'Your financial information is secure in our secure servers.',
                        time: ''
                    },
                    {
                        color: 'primary',
                        icon: 'CheckIcon',
                        title: 'Transparent register',
                        desc: 'No bank account or credit history is needed to get it.',
                        time: ''
                    },
                    {
                        color: 'primary',
                        icon: 'CheckIcon',
                        title: 'Practical',
                        desc: 'Easy to recharge it, and ready to use in the extensive network of businesses around the world.',
                        time: ''
                    }
                ],
                timelineVirtual: [
                    {
                        color: 'primary',
                        icon: 'CheckIcon',
                        title: 'Buy from your home',
                        desc: 'You can use it to pay for products and services on any website from the comfort of your home.',
                        time: ''
                    },
                    {
                        color: 'primary',
                        icon: 'CheckIcon',
                        title: 'Cheap',
                        desc: 'You can get your CactusCard quickly and easily without paying shipping cost.',
                        time: ''
                    },
                    {
                        color: 'primary',
                        icon: 'CheckIcon',
                        title: 'Easy to get',
                        desc: 'You can start using your card as soon as you receive it in your mail. No need to wait for delivery!',
                        time: ''
                    },
                    {
                        color: 'primary',
                        icon: 'CheckIcon',
                        title: 'Completely secure',
                        desc: 'Your financial information is secure in our secure servers.',
                        time: ''
                    },
                    {
                        color: 'primary',
                        icon: 'CheckIcon',
                        title: 'Comfortable',
                        desc: 'Being a digital card, there is no need to carry the traditional plastic card.',
                        time: ''
                    },
                    {
                        color: 'primary',
                        icon: 'CheckIcon',
                        title: 'Practical',
                        desc: 'It recharges easily, and is used in the extensive network of businesses around the world.',
                        time: ''
                    }
                ],
                genreOptions: [
                    {text: "M", value:"M"},
                    {text: "F", value:"F"}
                ],
                configdateTimePicker: {
                    locale: SpanishLocale,
                    maxDate: '2002-12-31'
                }
            }
        },
        computed: {
            ...mapState({
                user: state => state.user
            })
        },
        methods: {
            ...mapActions('user', ['getCountries', 'getStates', 'requestCard']),
            ...mapActions('alert', ['notificateErrorByCode']),

            sendData(){
                let payload={
                    cardType: this.cardType,
                    person: this.person,
                    address: this.address,
                    shippingAddress: this.shippingAddress
                }
                this.$vs.loading()
                this.requestCard(payload)
            },
            validateStep1() {
                return new Promise((resolve, reject) => {
                    this.$validator.validateAll('step-1').then(result => {
                        if(!this.cardType){
                            this.notificateErrorByCode(106)
                            reject("correct all values");
                        }else  if (result) {
                            resolve(true)
                        } else {
                            reject("correct all values");
                        }
                    })
                })
            },
            validateStep2() {
                return new Promise((resolve, reject) => {
                    this.$validator.validateAll("step-2").then(result => {
                        if (result) {
                            resolve(true)
                        } else {
                            reject("correct all values");
                        }
                    })
                })
            },
            validateStep3() {
                if(this.cardType =='virtual'){
                    this.shippingAddress={
                        address: this.address.address,
                        country: this.address.country,
                        state: this.address.state,
                        city:this.address.city,
                        zip:this.address.zip,
                        phoneCode:this.address.phoneCode,
                        area:this.address.area,
                        phoneNumber: this.address.phoneNumber,
                    }
                }
                return new Promise((resolve, reject) => {
                    this.$validator.validateAll("step-3").then(result => {
                        if (result) {
                            this.sendData();
                            resolve(true)
                        } else {
                            reject("correct all values");
                        }
                    })
                })
            },

        },
        watch: {
            'address.country'() {
                if (this.address.country) {
                    this.getStates(this.address.country)
                }
            },
            'shippingAddress.country'() {
                if (this.shippingAddress.country) {
                    this.getStates(this.shippingAddress.country)
                }
            },
            sameAddress(){
                if(this.sameAddress==true){
                    this.shippingAddress={
                        address: this.address.address,
                        country: this.address.country,
                        state: this.address.state,
                        city:this.address.city,
                        zip:this.address.zip,
                        phoneCode:this.address.phoneCode,
                        area:this.address.area,
                        phoneNumber: this.address.phoneNumber,
                    }
                }else{
                    this.shippingAddress={
                        address: '',
                        country: '',
                        state: '',
                        city: '',
                        zip: '',
                        phoneCode: '',
                        area: '' ,
                        phoneNumber: ''
                    }
                }
            },

        },
        created() {
            this.getCountries()

        },
        components: {
            FormWizard,
            TabContent,
            VxTimeline,
            flatPickr
        }
    }
</script>
<style lang="scss">
    #dashboard-analytics {
        .active-card-type{
            background-color: #00C853;
        }
        .greet-user{
            position: relative;

            .decore-left{
                position: absolute;
                left:0;
                top: 0;
            }
            .decore-right{
                position: absolute;
                right:0;
                top: 0;
            }
        }

        @media(max-width: 576px) {
            .decore-left, .decore-right{
                width: 140px;
            }
        }
    }

</style>

